import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../action/user";
import { useWebSocket } from "../../hooks/useWebSocket";

export const ProtectedRoute = ({ children }) => {
    const { token } = useAuth();
    const [ user ] = useLocalStorage("user", null)
    const { startWebSocket } = useWebSocket()

    const webScocketConnection = useSelector(state => state.webSocket.connection)

    const dispatch = useDispatch()

    const pathName = window.location.pathname

    useEffect(() => {
        if (user) {
            dispatch(updateUser(user))
        }
    }, [user])

    useEffect(() => {
        if (user && webScocketConnection.readyState === WebSocket.CLOSED) {
            startWebSocket(user.uuid, user.uuidCompany)
        }
    }, [user, webScocketConnection.readyState])
    
    if (!token) return <Navigate to="/auth" />
    else if (user.type === "employee" && pathName === "/acessos") return <Navigate to="/" />
    return children;
};